div#watermark {
  position: fixed;
  bottom: 2%;
  right: 10px;
  opacity: 0.5;
  color: gray;
  background-color: #a00000;
  border-radius: 10px;
  height: 96%;
  width: 50px;
  white-space: nowrap;
  box-sizing: border-box;
}

div#watermark h1 {
  transform: rotate(90deg);
  margin-top: 40vh;
}
