/*
 * These styles augment those in latex/styles.css
 * Generally these additional styles are for elements add by the tex4ht *.cfg files
 * in ci/latex or the the saltyvagrant.4ht
 */

$description-indent: 40px;


div.abstract {
  padding-left: 25pt;
  padding-right: 25pt;
}

.inline-cmd {
  font-family: monospace,monospace;
  background-color: #f1f1ff;
}

.sectionHead {
  margin-top: 5em;
}

.subsectionHead {
  margin-top: 3em;
}

.book-content > h2.likechapterHead {
  margin-top: 1.5em;
}

.book_part-nav {
  padding: 1em 0 1em;
  text-align: justify;
}

.book_part-nav:after {
  content: '';
  width: 100%;
  display: inline-block;
}

.book_part-nav > a {
  display: inline-block;
}

/* Fudge to correct separation of svlisting line numbers from code */
pre.fancyvrb .cmr-6 {
  padding-right:12pt;
}

/* Fudge to correct separation of minted line numbers from code */
pre.fancyvrb .ecrm-0600 {
  display: inline-block;
  width: 2.5em;
}

pre.fancyvrb {
  overflow-x: scroll;
  overflow-wrap: normal;
  white-space: pre;
  margin-top: 1em;
  line-height: 1.2em
}

/* Add a little breathing room to figures */
figure.figure img {
  margin-top: 1em;
}

figcaption.caption {
  margin-top: 1em;
}

/* Description lists, to more closely match PDF output */

dl.description {
  margin-left: $description-indent;
  margin-top: 1em;
}

dt.description {
  display: inline-block;
  margin-left: -$description-indent;
}

dt.description::after {
  content: "\00a0";
}

dd.description {
  display: inline;
  margin-inline-start: 0;
}

dd.description::after {
  display: block;
  content: '';
}

/* Boxnotes */

div.boxnote {
  margin: 2em 0 2em 0;
  border-radius: 5mm;
}

.boxnote div.tcolorbox-title {
  padding-left: 10px;
  padding-right: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: grey;
  color: white;
}

.boxnote div.tcolorbox-content {
  padding: 1em 2em 1em 2em;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: lightgrey;
  border: 2px solid grey;
}

/* Followalong */

div.followalong {
  margin: 2em 0 2em 0;
}

.followalong div.tcolorbox-title {
  padding-left: 10px;
  padding-right: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: blue;
  color: white;
}

.followalong div.tcolorbox-content {
  padding: 1em 2em 1em 2em;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: lightblue;
  border: 2px solid blue;
}


/* Author note */

div.authornote {
  margin: 2em 0 2em 0;
}

.authornote div.tcolorbox-title {
  padding-left: 10px;
  padding-right: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: yellow;
  color: black;
}

.authornote div.tcolorbox-content {
  padding: 1em 2em 1em 2em;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: lightyellow;
  border: 2px solid yellow;
}

.tocat{
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 10px;
}

.tocat summary:focus {
  outline-style: none;
}

.tocat summary:hover {
  cursor: pointer;
}
