.series{
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 10px;
}

.series summary:focus {
  outline-style: none;
}

.series summary:hover {
  cursor: pointer;
}

.series-list {
  margin-top: 2rem;
  margin-bottom: 5rem;
}

.series-heading {
  margin-top: 2em;
}

.series-posts {
  margin-top: 1em;
  list-style-type: none;
}

.sv-dt-modified {
  font-size: 60%;
}

.svlisting-title, .svout-title {
  display: flex;
  justify-content: flex-end;
  margin-bottom: -15px;
  margin-top: 5px;
}

.svlisting-title>div, .svout-title>div {
  background-color: #f1f1f1;
  text-align: right;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 5px;
  padding-left: 1rem;
  padding-right: 10px;
  font-size: small;
  font-weight: 400;
  font-style: italic;
}
