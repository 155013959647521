// Placeholder to allow defining custom styles that override everything else.
// (Use `_sass/minima/custom-variables.scss` to override variable defaults)


#sitelogo {
  height: calc(2rem);
  padding-right: 10px;
}


ul.pager { 
  text-align: center;
  list-style: none;
}

ul.pager li {
  display: inline;
  border: 1px solid black;
  padding: 10px;
  margin: 5px;
}

ul.pager li.selected {
  background-color: $svcyan;
}

div.tag-list {
  font-size: 10pt;
  padding: 10px;
  border: 1px solid $tag-box-border;
  border-radius: 5px;
  background-color: $tag-box-bgcolor;
  margin: 5px;
}

div.tag-list h1 {
  font-size: 1.1em;
  display: inline;
}

div.tag-list ul {
  display: inline;
  list-style: none;
  margin-left: 15x;
}

div.tag-list li {
  display: inline;
}

div.tag-list li::after {
  content: ", ";
}

div.tag-list li:last-child::after {
  content: "";
}
